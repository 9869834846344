'use client';

import Logo from "@/components/logo";
import NameLogo from "@/components/name-logo";
import PlanBadge from "@/components/plan-badge";
import SidebarNavigation from "@/components/sidebar-navigation";
import SymbolLogo from "@/components/symbol-logo";
import { Post } from "@/lib/posts/post";
import { NotificationType, showNotification, uiEventsBus } from "@/lib/ui/notifications";
import { MenuItem, Navigation, NavigationSection } from "@/lib/ui/types";
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from "@headlessui/react";
import { Bars3Icon, BellIcon, ChevronDownIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect, useState } from "react";

type Props = {
  signedIn: boolean,
  planName: string,
  conversationHistory: Array<{ id: string, title: string }>,
  promotedPosts: Post[],
  currentUserPictureNav: ReactNode,
  currentUserNameAndPictureNav: ReactNode,
}

function createMenu(conversationId: string): MenuItem[] {
  return [
    {
      label: 'Delete',
      onClick: async () => {
        if (window.confirm('Are you sure you want to delete this conversation?')) {
          const response = await fetch(`/api/conversations/${conversationId}/delete`, { method: "POST" });
          if (!response.ok || !response.body) {
            showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: response.statusText });
          } else {
            uiEventsBus.emit("conversationDeleted", { conversationId });
            showNotification({ type: NotificationType.Success, title: 'Success', description: response.statusText });
          }
        }
      }
    }
  ];
}

export default function SidebarAndHeader({ planName, signedIn, conversationHistory, promotedPosts, currentUserPictureNav, currentUserNameAndPictureNav }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  const latestPost = promotedPosts.at(-1);

  const [historyNavigation, setHistoryNavigation] = useState<Navigation>([
    {
      name: 'Research History',
      items: conversationHistory.map((conversation) => {
        return {
          name: conversation.title,
          href: `/research/${conversation.id}`,
          menu: createMenu(conversation.id),
        };
      })
    },
  ]);

  const [commonNavigation, setCommonNavigation] = useState<Navigation>([
    {
      name: '',
      items: [
        { name: 'New Research', href: '/research', icon: 'PencilSquareIcon' }
      ]
    },
  ]);

  const [userNavigation, setUserNavigation] = useState<Navigation>([
    {
      name: '',
      key: 'user-navigation',
      items: [
        { name: 'News', href: '/user/news', icon: 'BellIcon', count: promotedPosts.length > 0 ? promotedPosts.length.toString() : undefined },
        { name: 'Billing and Plans', href: '/user/plan', icon: 'CreditCardIcon' },
        { name: 'Support', href: '/user/support', icon: 'QuestionMarkCircleIcon' },
      ]
    },
  ]);

  useEffect(() => {
    const callback = ({ conversationId, userMessage }: { conversationId: string, userMessage: string }) => {
      setHistoryNavigation((navigation) => {
        const historySection: NavigationSection | undefined = navigation.find(section => section.name === 'Research History');
        if (!historySection) return navigation;
        // need a copy so the component knows something has changed
        const newNavigation = [...navigation];
        if (!historySection.items.find(item => item.href === `/research/${conversationId}`)) {
          historySection.items.unshift({
            name: userMessage,
            href: `/research/${conversationId}`,
            current: true,
            menu: createMenu(conversationId),
          });
        }
        return newNavigation;
      });
    };

    const deletedCallback = ({ conversationId }: { conversationId: string }) => {
      const current = pathname === `/research/${conversationId}`;
      if (current) {
        router.push('/research');
      }
      setHistoryNavigation(navigation => {
        const historySection = navigation.find(section => section.name === 'Research History');
        // need a copy so the component knows something has changed
        const newNavigation = [...navigation];
        if (historySection) {
          historySection.items = historySection.items.filter(item => item.href !== `/research/${conversationId}`);
        }
        return newNavigation;
      });
    };

    uiEventsBus.on("conversationCreatedOrCloned", callback);
    uiEventsBus.on("conversationDeleted", deletedCallback);

    return () => {
      uiEventsBus.removeListener("conversationCreatedOrCloned", callback);
      uiEventsBus.removeListener("conversationDeleted", deletedCallback);
    };
  }, [pathname, router]);


  return (
    <>
      <div className="max-sm:hidden print:hidden flex flex-col gap-y-5 w-64 sticky h-screen top-0 left-0 overflow-hidden bg-sidebar-background py-2">
        <Link className="flex-none px-4 flex flex-row items-start gap-x-2" href="/research">
          <Logo />
          <PlanBadge planName={planName} />
        </Link>
        <div className="flex-none px-5">
          <SidebarNavigation navigation={commonNavigation} setNavigation={setCommonNavigation} />
        </div>
        <div className="flex-1 overflow-y-auto overflow-x-hidden px-5">
          {signedIn && <SidebarNavigation navigation={historyNavigation} setNavigation={setHistoryNavigation} />}
          {!signedIn &&
            <div className="text-xs">
              <div className="font-semibold leading-6 mb-2 text-nowrap">Research History</div>
              <Link href={`/auth/signin?callbackUrl=/${encodeURIComponent('/research')}`} prefetch={false} className="underline">
                Sign in to access your research history
              </Link>
            </div>
          }
        </div>
        <div className="flex-none px-5">
          <SidebarNavigation navigation={userNavigation} setNavigation={setUserNavigation} />
        </div>
        <div className="flex-none flex px-4 pt-4 pb-2 border-t border-standard-ring">
          {currentUserNameAndPictureNav}
        </div>
      </div>

      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 sm:hidden print:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex flex-col gap-y-5 overflow-hidden bg-sidebar-background py-2">
              <Link className="flex-none px-4 flex flex-row gap-x-2 items-start" href="/research">
                <SymbolLogo />
                <NameLogo />
                <PlanBadge planName={planName} />
              </Link>
              <div className="flex-none px-5">
                <SidebarNavigation navigation={commonNavigation} setNavigation={setCommonNavigation} />
              </div>
              <div className="flex-1 overflow-y-auto overflow-x-hidden px-5">
                {signedIn && <SidebarNavigation navigation={historyNavigation} setNavigation={setHistoryNavigation} />}
                {!signedIn &&
                  <div className="text-xs">
                    <div className="font-semibold leading-6 mb-2 text-nowrap">Research History</div>
                    <Link href={`/auth/signin?callbackUrl=/${encodeURIComponent('/research')}`} prefetch={false} className="underline">
                      Sign in to access your research history
                    </Link>
                  </div>
                }
              </div>
              <div className="flex-none px-5">
                <SidebarNavigation navigation={userNavigation} setNavigation={setUserNavigation} />
              </div>
              <div className="flex-none flex px-4 pt-4 pb-2 border-t border-standard-ring">
                {currentUserNameAndPictureNav}
              </div>
            </div>

          </DialogPanel>
        </div>
      </Dialog>


      <header className="sm:hidden print:block sticky top-0 z-10 h-13 w-full shadow bg-page-background/95 py-2 px-1">
        <nav className="container mx-auto flex flex-row items-center gap-x-1">
          <button onClick={() => setSidebarOpen(true)} className="flex-1 flex items-center gap-x-1 sm:-mx-4 sm:px-4 -my-2 py-2 active:bg-list-item-current active:rounded-md">
            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            <NameLogo />
            <PlanBadge planName={planName} />
            <ChevronDownIcon className="h-5 w-5 text-tertiary-text" aria-hidden="true" />
          </button>
          <div className="flex-auto">
          </div>
          <Link href="/research" prefetch={false} className="active:bg-list-item-current active:rounded-md">
            <PencilSquareIcon className="h-8 w-8 shrink-0" />
          </Link>
          <Link href="/user/news" prefetch={false} className="active:bg-list-item-current active:rounded-md">
            <div className="relative">
              <BellIcon className="h-8 w-8 shrink-0" />
              {!!latestPost &&
                <svg viewBox="0 0 6 6" aria-hidden="true" className="absolute top-0 right-0 size-1.5 fill-red-500">
                  <circle r={3} cx={3} cy={3} />
                </svg>
              }
            </div>
          </Link>
          <div>
            {currentUserPictureNav}
          </div>
        </nav>
      </header>
    </>
  );
}
