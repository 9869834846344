/* eslint-disable @typescript-eslint/no-require-imports */

import { markdownToHtml } from '@/lib/text-utils/markdown';
import DOMPurify from 'dompurify';

export default function ChatMessageContent({ content }: { content?: string }) {

  if (!content)
    return;

  const rawHTML = markdownToHtml(content);

  let w;

  if (typeof window === 'undefined') {
    const { JSDOM } = require('jsdom');
    w = new JSDOM('').window;
  } else {
    w = window;
  }

  const purify = DOMPurify(w);
  const safeHTML = purify.sanitize(rawHTML, {
    ADD_ATTR: ['target', 'rel']
  });

  return <div dangerouslySetInnerHTML={{ __html: safeHTML }} />;
}
