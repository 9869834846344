import { marked } from 'marked';


// open links in new tab
marked.use({
  renderer: {
    link({ href, title, tokens }): string {
      const text = marked.Parser.parseInline(tokens);
      const titleAttr = title ? ` title="${title}"` : '';
      return `<a href="${href}"${titleAttr} target="_blank" rel="noopener noreferrer">${text}</a>`;
    }
  }
});

export function markdownToHtml(markdown: string): string {
  return marked.parse(markdown, { async: false });
}
