import(/* webpackMode: "eager" */ "/tmp/build_a0f73842/node_modules/@headlessui/react/dist/components/menu/menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/tmp/build_a0f73842/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_a0f73842/src/app/(product)/_components/sidebar-and-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_a0f73842/src/components/news-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_a0f73842/src/components/notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_a0f73842/src/components/profile-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_a0f73842/src/components/sign-in-link.tsx");
