
export default function PlanBadge({ planName }: { planName: string }) {
  return (
    <>
      {planName != 'Free' &&
        <span className="rounded-md px-2 py-1 text-xs font-medium text-red-text ring-1 ring-inset ring-focused-ring">
          {planName}
        </span>
      }
    </>
  );
}
